<template>
    <div class="gallery-container">
        <div class="gallery-placeholder">
            <r-tiny-slider ref="tiny" v-if="hasGallery" @indexChanged="onIndexChanged">
                <medias :gallery="gallery" :size="thumbSize" @click="onMediaThumbClick" />
            </r-tiny-slider>
            <div v-else class="empty">
                <r-loading-spinner :visible="!wasGalleryLoaded" />
            </div>
        </div>

        <portal to="root-portal-target">
            <media-modal
                v-if="isMediaModalVisible"
                :media="media"
                @close="isMediaModalVisible = false"
                @prev="onPrev"
                @next="onNext"
            />
        </portal>
    </div>
</template>

<script>
import Medias from './medias';
import { get, filter } from 'utils/lodash';
import MediaModal from 'components/gallery/asset/modal';
import { mapActions, mapGetters } from 'vuex';
export default {
    components: {
        Medias,
        MediaModal,
    },
    props: {
        id: {
            type: [String, Number],
            default: null,
        },
        thumbSize: {
            type: Number,
            default: 170,
        },
    },
    data: () => ({
        gallery: null,
        isMediaModalVisible: false,
        media: null,
    }),
    computed: {
        ...mapGetters('map', { mapId: 'id' }),
        hasGallery() {
            return get(this, 'gallery.id') !== undefined;
        },
        wasGalleryLoaded() {
            return this.gallery !== null || this.id === null;
        },
    },
    watch: {
        id: {
            immediate: true,
            handler: function () {
                if (this.id) {
                    this.fetchGallery();
                }
            },
        },
    },
    methods: {
        ...mapActions('gallery', ['get']),
        async fetchGallery() {
            this.gallery = await this.get({
                map: this.mapId,
                gallery: this.id,
            });

            this.gallery.media = filter(this.gallery.media, (item) => item.asset.status == 'ready');
        },
        onMediaThumbClick(media) {
            this.media = media;
            this.isMediaModalVisible = true;
        },
        onIndexChanged(index) {
            this.media = this.gallery.media[index];
        },
        onPrev() {
            this.$refs.tiny.slider.goTo('prev');
        },
        onNext() {
            this.$refs.tiny.slider.goTo('next');
        },
        beforeDestroy() {
            this.slider = null;
        },
    },
};
</script>

<style lang="scss">
@import 'scss/components/_gallery';

.thumb .thumb-img {
    min-width: 177px;
    height: 177px;
    border-radius: 0;
    background-color: black;
}

.gallery-container {
    margin: 0 -20px;
    margin-top: 20px;

    .gallery-placeholder {
        border: none;
        padding: 0;
    }
}
</style>
